import React, { Component } from 'react'
import Helmet from 'react-helmet'
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

export default class Header extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        ReactGA.initialize(this.props.headerData.gaID);
        ReactGA.pageview(window.location.pathname + window.location.search);
        TagManager.initialize({ gtmId: this.props.headerData.gtmID })
    }

    render() {
        return (
            <Helmet
                title={this.props.headerData.pageTitle}
                meta={[
                    { name: 'description', content: this.props.headerData.descricao },
                    { name: 'keywords', content: this.props.headerData.keyWords },
                ]}
            >
                {/* <script>
                    {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                        n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '${this.props.headerData.pixelID}');
                    fbq('track', 'PageView');`}
                </script> */}

            </Helmet>
        )
    }
}