import React from 'react';
import './footer.scss';
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'


const Footer = () => (
  <div className="footer-box">
    <div className="footer-box-container">
      <div className="item-footer">
        <p className="p-footer"><AnchorLink href={'#' + "home"} style={{ color: 'inherit', textDecoration: 'none' }}> Home</AnchorLink></p>
        <p className="p-footer"><AnchorLink href={'#' + "sobre"} style={{ color: 'inherit', textDecoration: 'none' }}> Sobre</AnchorLink></p>
        <p className="p-footer"><AnchorLink href={'#' + "produtos"} style={{ color: 'inherit', textDecoration: 'none' }}> Produtos</AnchorLink></p>
        <p className="p-footer"><AnchorLink href={'#' + "contato"} style={{ color: 'inherit', textDecoration: 'none' }}> Contato</AnchorLink></p>
        

      </div>

      <div>
        <p className="p-footer">SFLabs do Brasil &#174; | <Link to="/politica/" style={{ color: 'inherit', textDecoration: 'none' }}>Política de privacidade.</Link> </p>
      
      </div>

    </div>

  </div>
)

export default Footer
