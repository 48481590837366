import React, { Component } from 'react'
import Topbar from '../components/topbar/topbar';
import SideBar from '../components/sidebar/sidebar';
import Footer from '../components/footer/footer';

export default class Layout extends Component {
    tel = "(17) 3325 - 3634";
    telLink = "551733253634"
    telWpp = "(17) 98134-2217"
    telWppLink = "551798134-2217"

    constructor(props) {
        super(props)
        this.state = {
            sidebar: {
                sidebarState: "hide-sidebar",
                filterState: "hide-filter"
            },
            flag: true
        }
    }

    toggleOffSideBar() {
        this.setState({
            sidebar: {
                sidebarState: "hide-sidebar",
                filterState: "hide-filter",

            },
            flag: true
        })
    }
    toggleOnSideBar() {
        this.setState({
            sidebar: {
                sidebarState: "show-sidebar",
                filterState: "show-filter"
            },
            flag: false


        })
    }
    render() {
        return (
            <div style={{ top: '0', height: '100%', margin: '0', padding: '0' }}  >
                {/* <ComplementoBar></ComplementoBar> */}
                <Topbar address="São Carlos" telLinkWpp={this.telWppLink} telLink={this.telLink} tel={this.tel} toggleOn={this.toggleOnSideBar.bind(this)} toggleOff={this.toggleOffSideBar.bind(this)} flag={this.state.flag}></Topbar>
                {this.props.children}
                <Footer></Footer>
                <SideBar sidebarStateProps={this.state.sidebar} toggleOff={this.toggleOffSideBar.bind(this)}></SideBar>
            </div>

        )
    }
}
