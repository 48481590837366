import React from 'react';
import './contato1.scss';

class Contact extends React.Component {
    flag = 0;
    constructor(props) {
        super(props);
        // let isMobile = this.checkForMobile()
        // if (!isMobile) {
            this.state = { whatsappLink: `https://web.whatsapp.com//send?phone=${this.props.telLink}` }
        // } else {
        //     this.state = { whatsappLink: `https://wa.me/${this.props.telLink}` }
        // }
    }

    componentDidMount(){
        let isMobile = this.checkForMobile()
        if (!isMobile) {
            this.setState  ({ whatsappLink: `https://web.whatsapp.com//send?phone=${this.props.telLink}` })
        } else {
            this.setState  ({ whatsappLink: `https://wa.me/${this.props.telLink}` })
        }
    }

    checkForMobile() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    render() {
        return (
            <div className="contact" id="contato">
                <div className="categorias-contact">
                    <i className="material-icons icone-contact">phone</i>
                    <div className="info-contact">
                        <p className="p-contact p-label">Entre em contato</p>
                        <a className="botao" href={this.state.whatsappLink} target="_blank" rel="noopener">
                            <p className="p-contact">{this.props.tel}</p>
                        </a>
                    </div>
                </div>

                <div className="categorias-contact mail">
                    <i className="material-icons icone-contact ">mail_outline</i>
                    <div className="info-contact">
                        <p className="p-contact p-label">Mande um Email</p>
                        <a className="botao" href="mailto: ${this.props.email}" rel="noopener">
                            <p className="p-contact">{this.props.email}</p>
                        </a>
                    </div>
                </div>

                <div className="categorias-contact place-contact">
                    <i className="material-icons icone-contact">place</i>
                    <div className="info-contact">
                        <p className="p-contact p-label">{this.props.address}</p>
                        <p className="p-contact p-label">{this.props.city}</p>
                    </div>
                </div>
            </div>

        )
    }
}

export default Contact










