import React from 'react'
import './sidebar.css'
import { Link } from 'gatsby'

export default class SideBar extends React.Component {
   initial = true;
    constructor(props) {
        super(props)
       
    }
    itensContent = [
        ["home", "/#home"],
        ["sobre", "/#sobre"],
        ["produtos", "/#produtos"],
        ["carreira", "http://www.carreira.sflabs.com.br"],
        ["contato", "/#contato"]
      ]
    toggleOffSideBar() {
        this.props.toggleOff()
    }

    teste(){
        this.props.toggleOff()
    }

    generateItens(itensContent) {
        return itensContent
          .map(item =>
            <p className="pStyle-side" key={item} >
              <a className="pStyle1" href={item[1]} style={{ color: 'white', textDecoration: 'none' }}>  {item[0]}</a>
            </p>
          )
      }
      
    render() {
        if(this.initial){
            // console.log("igual a true")
            this.initial = false
            return null;
        }
        return (
            <div className={"sidebar-container " + this.props.sidebarStateProps.filterState}  >
                <div className={"sidebar-sidebar " + this.props.sidebarStateProps.sidebarState} style={{paddingTop:'7vh'}}>
                    {/* <i className="material-icons" style={{ color: 'white', fontSize: '2.1em', padding: '10px 9.5%' }} >close</i> */}
                    {this.generateItens(this.itensContent)}
                </div>
                <div style={{position:'relative',left:'300px',height:'100%',width:'30%'}} onClick={this.toggleOffSideBar.bind(this)}>

                </div>
            </div>

        )
    }

}